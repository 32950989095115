/** @jsx jsx */
import * as React from "react";
import { jsx, Heading } from "theme-ui";
import HR from "../../yir-ui/HR";
import EmailForm from "./email-form";

const PostScript = () => {
  return (
    <>
      <section sx={{ pt: 3, textAlign: "center" }}>
        Want more? Subscribe for updates!
        <EmailForm />
      </section>
    </>
  );
};

export default PostScript;
